import * as React from "react"
import { optionalReactDOM } from "../utils/optionalReactDOM"

export function elementForComponent(component: React.ReactInstance | null | undefined): HTMLElement | null {
    const ReactDOM = optionalReactDOM()
    if (!ReactDOM) {
        return null
    }
    const element = ReactDOM.findDOMNode(component) as HTMLElement | null
    return element
}
