import { Draggable } from "../Draggable"
import { FramerEvent } from "../../events/FramerEvent"
import * as React from "react"
import { DragEventHandler, DraggableProps, DragEvents } from "../hoc/WithDragging"
import {
    Rect,
    PropertyControls,
    ControlType,
    LayerProps,
    ConstraintProperties,
    DeprecatedFrame,
    DeprecatedFrameWithEventsProps,
} from "../../render"
import { isRectProviding } from "../utils/RectProvider"
import { getObservableNumber } from "../../utils/observable"
import { Animatable } from "../../animation/Animatable/Animatable"
import { EmptyState } from "../EmptyState"
import { ConsumeParentSize, deprecatedParentSize } from "../../render/types/NewConstraints"

type DraggableType = typeof DeprecatedFrame

export type ScrollDirection = "horizontal" | "vertical" | "both"

/** @public */
type DraggableFrameProps = Partial<DeprecatedFrameWithEventsProps> & Partial<DraggableProps<DraggableType>>
export type ScrollEventHandler = (event: FramerEvent, scrollComponent: DeprecatedScroll) => void

/**
 * @public
 */
export interface DeprecatedScrollEvents {
    /**
     * Called when scrolling starts.
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   function onScrollStart(evt: FramerEvent, scroll: Scroll) {
     *     console.log("Scroll started at: ", point.x, point.y)
     *   }
     *   return <Scroll onScrollStart={onScrollStart} {...props} />
     * }
     * ```
     * @param event - The `FramerEvent` for the scroll.
     * @param scrollComponent - The current `Scroll` component.
     * @internal
     */
    onScrollStart(event: FramerEvent, scrollComponent: DeprecatedScroll): void

    /**
     * Called periodically during scrolling.
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   function onScroll(evt: FramerEvent, scroll: Scroll) {
     *     console.log("Scrolling at: ", point.x, point.y)
     *   }
     *   return <Scroll onScroll={onScroll} {...props} />
     * }
     * ```
     * @param event - The `FramerEvent` for the scroll.
     * @param scrollComponent - The current `Scroll` component.
     * @internal
     */
    onScroll(event: FramerEvent, scrollComponent: DeprecatedScroll): void
    /**
     * Called periodically during scrolling.
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   function onScrollEnd(evt: FramerEvent, scroll: Scroll) {
     *     console.log("Scrolling ended at: ", point.x, point.y)
     *   }
     *   return <Scroll onScrollEnd={onScrollEnd} {...props} />
     * }
     * ```
     * @param event - The `FramerEvent` for the scroll.
     * @param scrollComponent - The current `Scroll` component.
     * @internal
     */
    onScrollEnd(event: FramerEvent, scrollComponent: DeprecatedScroll): void
    /**
     * Called when scrolling starts.
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   function onScrollSessionStart(evt: FramerEvent, scroll: Scroll) {
     *     console.log("Scrolling started at: ", point.x, point.y)
     *   }
     *   return <Scroll onScrollSessionStart={onScrollSessionStart} {...props} />
     * }
     * ```
     * @param event - The `FramerEvent` for the scroll.
     * @param scrollComponent - The current `Scroll` component.
     * @beta
     */
    onScrollSessionStart(event: FramerEvent, scrollComponent: DeprecatedScroll): void
    /**
     * Called when scrolling ends.
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   function onScrollSessionEnd(evt: FramerEvent, scroll: Scroll) {
     *     console.log("Scrolling ended at: ", point.x, point.y)
     *   }
     *   return <Scroll onScrollSessionEnd={onScrollSessionEnd} {...props} />
     * }
     * ```
     * @param event - The `FramerEvent` for the scroll.
     * @param scrollComponent - The current `Scroll` component.
     * @beta
     */
    onScrollSessionEnd(event: FramerEvent, scrollComponent: DeprecatedScroll): void
}

/**
 * The properties for the {@link Scroll} component, which are also available within other components, like {@link Page}.
 * @public
 */
export interface DeprecatedScrollProperties {
    /**
     * Set to `false` to prevent the user from being able to drag the scroll view.
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   return <Scroll {...props} draggingEnabled={false} />
     * }
     * ```
     */
    draggingEnabled: boolean
    /**
     * `DeprecatedScrollProperties` The scrolling direction, one of `"horizontal", "vertical"`
     * or `"both"`. Set to `"vertical"` by default.
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   return <Scroll {...props} direction="horizontal" />
     * }
     * ```
     */
    direction: ScrollDirection
    /**
     * Lock the current scrolling direction.
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   return <Scroll {...props} directionLock />
     * }
     * ```
     */
    directionLock: boolean
    /**
     * @internalremarks
     * Ignored and deprecated; see https://github.com/framer/company/issues/10018 for future direction
     * @deprecated mouseWheel is always enabled
     * @internal
     */
    mouseWheel: boolean
    /**
     * Horizontal offset of the scrollable content. Set to `null` by default
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   return <Scroll {...props} contentOffsetX={20} />
     * }
     * ```
     */
    contentOffsetX: number | Animatable<number> | null
    /**
     * Vertical offset of the scrollable content. Set to `null` by default.
     *
     * @remarks
     * ```jsx
     * const MyComponent = (props) => {
     *   return <Scroll {...props} contentOffsetY={20} />
     * }
     * ```
     */
    contentOffsetY: number | Animatable<number> | null
}

/**
 * The Scroll component takes the same props as {@link Frame} as well as the additional
 * interface defined below.
 * @public
 */
export interface DeprecatedScrollProps
    extends DeprecatedScrollProperties,
        Partial<DeprecatedFrameWithEventsProps>,
        LayerProps,
        Partial<DeprecatedScrollEvents>,
        Partial<DragEvents<DraggableType>> {}

/**
 * The Scroll component in Framer allows you create scrollable areas.
 * @remarks
 * It can be imported from the Framer Library and used in code components.
 * Add children that exceed the height or width of the component to create
 * horizontally or vertically scrollable areas.
 *
 * ```jsx
 * const MyComponent: React.SFC<DeprecatedScrollProps> = props => {
 *   return (
 *     <Scroll {...props} direction="vertical">
 *       <Frame width={props.width} height={props.height * 2}>
 *         Hello
 *       </Frame>
 *      </Scroll>
 *   )
 * }
 * ```
 *
 * See: {@link DeprecatedScrollProps} for its properties
 * @public
 */
export class DeprecatedScroll extends React.Component<Partial<DeprecatedScrollProps>> {
    /** @internal */
    static supportsConstraints = true

    /** @internal */
    static scrollProps: DeprecatedScrollProperties = {
        draggingEnabled: true,
        direction: "vertical",
        directionLock: true,
        mouseWheel: true,
        contentOffsetX: null,
        contentOffsetY: null,
    }

    /** @internal */
    static defaultProps: DeprecatedScrollProps = Object.assign(
        {},
        DeprecatedFrame.defaultProps,
        DeprecatedScroll.scrollProps,
        {
            overflow: "visible",
            background: "none",
            width: "100%",
            height: "100%",
        }
    )

    /** @internal */
    static propertyControls: PropertyControls<DeprecatedScrollProps> = {
        direction: {
            type: ControlType.SegmentedEnum,
            title: "Direction",
            options: ["vertical", "horizontal", "both"],
        },
        directionLock: {
            type: ControlType.Boolean,
            title: "Lock",
            enabledTitle: "1 Axis",
            disabledTitle: "Off",
            hidden(props) {
                return props.direction !== "both"
            },
        },
    }

    /** @internal */
    props: DeprecatedScrollProps

    private wrapHandlers(
        dragHandler?: DragEventHandler<DraggableType> | undefined,
        scrollHandler?: ScrollEventHandler
    ): DragEventHandler<DraggableType> | undefined {
        if (!scrollHandler) {
            return dragHandler
        }
        return (event: FramerEvent, draggable: typeof DeprecatedFrame) => {
            if (dragHandler) {
                dragHandler(event, draggable)
            }
            scrollHandler(event, this)
        }
    }

    /** @internal */
    render() {
        return (
            <ConsumeParentSize>
                {({ size: newParentSize }) => {
                    const parentSize = deprecatedParentSize(newParentSize)

                    const frameProps: Partial<DeprecatedFrameWithEventsProps> = Object.assign({}, this.props, {
                        parentSize,
                    })
                    Object.keys(DeprecatedScroll.scrollProps).map(key => {
                        delete frameProps[key]
                    })

                    // If there are no children we render a single child at the size of the component so we have visual feedback.
                    if (!this.props.children) {
                        return (
                            <DeprecatedFrame {...frameProps}>
                                <Draggable width={frameProps.width} height={frameProps.height} />
                            </DeprecatedFrame>
                        )
                    }

                    // TODO: Move this to Frame.contentFrame
                    const contentSize = { top: 0, left: 0, bottom: 0, right: 0 }
                    const { width, height } = DeprecatedFrame.rect(frameProps)
                    const children = React.Children.map(this.props.children, (child: React.ReactChild) => {
                        if (child === null || typeof child !== "object" || typeof child.type === "string") {
                            return child
                        }
                        const type = child.type
                        if (isRectProviding(type)) {
                            const frame = type.rect(child.props, parentSize)
                            if (frame) {
                                // TODO: move this to utils/frame as merge(frame: Frame)?
                                contentSize.top = Math.min(Rect.minY(frame), contentSize.top)
                                contentSize.left = Math.min(Rect.minX(frame), contentSize.left)
                                contentSize.bottom = Math.max(Rect.maxY(frame), contentSize.bottom)
                                contentSize.right = Math.max(Rect.maxX(frame), contentSize.right)
                            }
                        }
                        const update: Partial<{ width: number; height: number }> = {}
                        if (this.props.direction === "vertical") {
                            update.width = width
                        } else if (this.props.direction === "horizontal") {
                            update.height = height
                        }
                        return React.cloneElement(child, update)
                    })
                    const {
                        onScrollStart,
                        onScroll,
                        onScrollEnd,
                        onScrollSessionStart,
                        onScrollSessionEnd,
                    } = this.props
                    const w = getObservableNumber(width)
                    const h = getObservableNumber(height)
                    const contentW = Math.max(contentSize.right, w)
                    const contentH = Math.max(contentSize.bottom, h)
                    const x = Math.min(0, w - contentW)
                    const y = Math.min(0, h - contentH)
                    const constraints = {
                        x: x,
                        y: y,
                        width: contentW + contentW - w,
                        height: contentH + contentH - h,
                    }
                    const draggableProps: Partial<DraggableFrameProps> = {}
                    draggableProps.enabled = this.props.draggingEnabled
                    draggableProps.background = "none"
                    draggableProps.width = contentW
                    draggableProps.height = contentH
                    draggableProps.constraints = constraints
                    draggableProps.onMove = this.props.onMove
                    draggableProps.onDragSessionStart = this.wrapHandlers(
                        this.props.onDragSessionStart,
                        onScrollSessionStart
                    )
                    draggableProps.onDragSessionMove = this.props.onDragSessionMove
                    draggableProps.onDragSessionEnd = this.wrapHandlers(this.props.onDragSessionEnd, onScrollSessionEnd)
                    draggableProps.onDragAnimationStart = this.props.onDragAnimationStart
                    draggableProps.onDragAnimationEnd = this.props.onDragAnimationEnd
                    draggableProps.onDragDidMove = this.wrapHandlers(this.props.onDragDidMove, onScroll)
                    draggableProps.onDragDirectionLockStart = this.props.onDragDirectionLockStart
                    draggableProps.onDragStart = this.wrapHandlers(this.props.onDragStart, onScrollStart)
                    draggableProps.onDragEnd = this.wrapHandlers(this.props.onDragEnd, onScrollEnd)
                    draggableProps.onDragWillMove = this.props.onDragWillMove
                    draggableProps.horizontal = this.props.direction !== "vertical"
                    draggableProps.vertical = this.props.direction !== "horizontal"
                    draggableProps.directionLock = this.props.directionLock
                    draggableProps.mouseWheel = true // TODO: see https://github.com/framer/company/issues/10018 for future direction
                    draggableProps.left = this.props.contentOffsetX
                    draggableProps.top = this.props.contentOffsetY
                    draggableProps.preserve3d = this.props.preserve3d

                    return (
                        <DeprecatedFrame {...frameProps}>
                            <Draggable {...draggableProps}>{children}</Draggable>
                            <EmptyState
                                children={this.props.children!}
                                size={{ width: w, height: h }}
                                title={"Connect to scrollable content"}
                            />
                        </DeprecatedFrame>
                    )
                }}
            </ConsumeParentSize>
        )
    }
}
