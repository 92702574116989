import * as React from "react"

import { DeprecatedBackgroundProperties } from "../traits/Background"
import { Animatable } from "../../animation/Animatable"
import { BackgroundImage } from "../types/BackgroundImage"
import { Rect } from "../types/Rect"
import { RenderEnvironment } from "../types/RenderEnvironment"
import { QualityOptions, setImageForFill } from "../utils/imageForFill"
import { LayerProps } from "../presentation/Layer"

/** @internal */
export function collectBackgroundImageFromProps(
    props: Partial<DeprecatedBackgroundProperties & LayerProps>,
    rect: Rect | null,
    style: React.CSSProperties,
) {
    let background = Animatable.get(props.background, null)
    if (BackgroundImage.isImageObject(background)) {
        const { _forwardedOverrides, id } = props
        const src = _forwardedOverrides && id ? _forwardedOverrides[id] : undefined
        if (src && typeof src === "string") {
            background = { ...background, src }
        }
        const { target, zoom } = RenderEnvironment
        if (target !== undefined && zoom) {
            // Don't load lower quality images when the image changed, because lower quality images are generated async
            const qualityOptions: QualityOptions | null = rect
                ? {
                      frame: rect,
                      target,
                      zoom,
                  }
                : null

            setImageForFill(background, qualityOptions, style)
        }
    }
}
