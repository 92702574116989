import { hash as stringHash } from "../../utils/string"
import { GradientColorStop } from "./GradientColorStop"
import { gradientColorStops, gradientColorStopsHash } from "../utils/gradientColorStops"
import { SimpleGradient, isSimpleGradient } from "./SimpleGradient"
import { MultiStopGradient, isMultiStopGradient } from "./MultiStopGradient"

/**
 * @public
 */
export interface RadialGradientBase {
    alpha: number
    widthFactor: number
    heightFactor: number
    centerAnchorX: number
    centerAnchorY: number
}

/**
 * @public
 */
export type RadialGradient = RadialGradientBase & (SimpleGradient | MultiStopGradient)

const radialGradientKeys: (keyof RadialGradientBase)[] = [
    "widthFactor",
    "heightFactor",
    "centerAnchorX",
    "centerAnchorY",
    "alpha",
]

/**
 * @public
 */
export namespace RadialGradient {
    /**
     * @param value -
     * @public
     */
    export function isRadialGradient(value: any): value is RadialGradient {
        return (
            value &&
            radialGradientKeys.every(key => key in value) &&
            (isSimpleGradient(value) || isMultiStopGradient(value))
        )
    }

    /** @internal */
    export function hash(radialGradient: RadialGradient): number {
        return (
            radialGradient.centerAnchorX ^
            radialGradient.centerAnchorY ^
            radialGradient.widthFactor ^
            radialGradient.heightFactor ^
            gradientColorStopsHash(radialGradient, radialGradient.alpha)
        )
    }

    /** @alpha */
    export function toCSS(radialGradient: RadialGradient) {
        const { alpha, widthFactor, heightFactor, centerAnchorX, centerAnchorY } = radialGradient
        const stops = gradientColorStops(radialGradient, alpha)
        const cssStops = stops.map(stop => `${stop.value} ${stop.position * 100}%`)
        return `radial-gradient(${widthFactor * 100}% ${heightFactor * 100}% at ${centerAnchorX *
            100}% ${centerAnchorY * 100}%, ${cssStops.join(", ")})`
    }
}
