import { Color } from "../types/Color"
import { MotionStyle } from "framer-motion"

export interface TextColorProperties {
    color: Color | string
}

export function collectTextColorFromProps(props: Partial<TextColorProperties>, style: MotionStyle) {
    const { color } = props
    if (typeof color === "string") {
        style.color = color
    } else if (Color.isColorObject(color)) {
        style.color = color.initialValue || Color.toRgbString(color)
    }
}
