import * as React from "react"
import { MotionValue, motionValue, PanInfo, useAnimation, AnimationControls } from "framer-motion"
import { PageContainer } from "./PageContainer"
import { LayerProps, ControlType, Size, FrameProps, FrameWithMotion, Rect, constraintsEnabled } from "../render"
import { EmptyState } from "./EmptyState"
import { FramerEvent } from "../events"
import { RenderTarget } from "../render/types/RenderEnvironment"
import { isReactChild, isReactElement } from "../utils/type-guards"
import { ScrollProps, ScrollEvents } from "./Scroll"
import { isMotionValue } from "../render/utils/isMotionValue"
import { inertia } from "popmotion"
import { Omit } from "../utils/omit"
import { paddingFromProps, makePaddingString, PaddingProps } from "./utils/paddingFromProps"
import { injectComponentCSSRules } from "../render/utils/injectComponentCSSRules"
import { addPropertyControls } from "../utils/addPropertyControls"
import { unwrapFrameProps } from "../render/presentation/Frame/FrameWithMotion"
import { useMeasureSize } from "./Scroll/useMeasureSize"
import { useWheelScroll } from "./Scroll/useWheelScroll"
import { warnOnce } from "../utils/warnOnce"

export type PageDirection = "horizontal" | "vertical"

namespace ContentDimension {
    export const Auto: PageContentDimension = "auto"
    export const Stretch: PageContentDimension = "stretch"
}
export type PageContentDimension = "auto" | "stretch"
const pageContentDimensionOptions: PageContentDimension[] = [ContentDimension.Auto, ContentDimension.Stretch]
const pageContentDimensionTitles = pageContentDimensionOptions.map(option => {
    switch (option) {
        case ContentDimension.Auto:
            return "Auto"
        case ContentDimension.Stretch:
            return "Stretch"
    }
}) as string[]

/**
 * @public
 */
export type PageAlignment = "start" | "center" | "end"

const pageAlignmentOptions: PageAlignment[] = ["start", "center", "end"]
const genericAlignmentTitles = pageAlignmentOptions.map(option => {
    switch (option) {
        case "start":
            return "Start"
        case "center":
            return "Center"
        case "end":
            return "End"
    }
})
const horizontalAlignmentTitles = pageAlignmentOptions.map(option => {
    switch (option) {
        case "start":
            return "Left"
        case "center":
            return "Center"
        case "end":
            return "Right"
    }
})
const verticalAlignmentTitles = pageAlignmentOptions.map(option => {
    switch (option) {
        case "start":
            return "Top"
        case "center":
            return "Center"
        case "end":
            return "Bottom"
    }
})

/**
 * Page effects change the behavior of the transition when swiping between pages.
 * By default there is no page effect applied.
 * @remarks
 * ```jsx
 * import * as React from "react"
 * import { Page, PageEffect } from "framer"
 *
 * export function MyComponent() {
 *  return <Page defaultEffect={"cube"} />
 * }
 * ```
 *
 * `"none"` - No custom effect is applied. This is the default.
 * `"cube"` - Each page is positioned as a 3D cube, connected to the current page.
 * `"coverflow"` - Each page is positioned in 3D, behind the current page.
 * `"wheel"` - Each page is gently titled in 3D, like a wheel.
 * `"pile"` - Each page is stacked behind the current page.
 * @public
 */
export type PageEffect = "none" | "cube" | "coverflow" | "wheel" | "pile"

const pageEffectOptions: PageEffect[] = ["none", "cube", "coverflow", "wheel", "pile"]
const pageEffectTitles = pageEffectOptions.map(option => {
    switch (option) {
        case "none":
            return "None"
        case "cube":
            return "Cube"
        case "coverflow":
            return "Cover Flow"
        case "wheel":
            return "Wheel"
        case "pile":
            return "Pile"
    }
})

/**
 * Information about the current effect.
 * @public
 */
export interface PageEffectInfo {
    /**
     * The offset of this page, in pixels, measured from the left-most part of the container.
     * @public
     */
    offset: number
    /**
     * The offset of this page, normalised to the page size.
     *
     * For instance, if each page is `200` pixels wide, and we're on page index `1`, the `normalizedOffset` of page index `0` will be `-1`.
     * @public
     */
    normalizedOffset: number
    /**
     * The `width` and `height` of the page.
     * @public
     */
    size: Size
    /**
     * The index of the current page. The first page is `0`, the second is `1` and so on.
     * @public
     */
    index: number
    /**
     * The direction of page scrolling, `"horizontal"` or `"vertical"`
     * @public
     */
    direction: PageDirection
    /**
     * The gap between each page, in pixels.
     * @public
     */
    gap: number
    /**
     * The total number of pages.
     *
     * @public
     */
    pageCount: number // Infinity when looped
}
export type CustomPageEffect = (info: PageEffectInfo) => PageEffectValues

export type PageEventHandler = (event: FramerEvent) => void

export type PageEffectValues = { [key: string]: string | number | boolean }

/**
 * Event callbacks for the Page component, can be used to react to and co-ordinate
 * with other components.
 *
 * @public
 */
export interface PageEvents {
    /**
     * A callback that will be invoked when changing the page.
     * @remarks
     * This will be invoked when the drag animation begins or when the page changes
     * programatically. It can be used to co-ordinate with other behaviors.
     *
     * @param currentIndex - The current page number
     * @param previousIndex - The index of the previous page
     * @public
     * @remarks
     * ```jsx
     * <Page
     *     onChangePage={(current, previous) => {
     *         console.log(current, previous)
     *     }}
     * />
     * ```
     */
    onChangePage(currentIndex: number, previousIndex: number): void
}

/**
 * All properties that can be used with the {@link Page} component it also extends all {@link ScrollProps} properties.
 * ```jsx
 * <Page
 *   direction={"horizontal"}
 *   contentWidth={"stretch"}
 *   contentHeight={"stretch"}
 *   alignment={"center"}
 *   currentPage={0}
 *   animateCurrentPageUpdate={true}
 *   gap={10}
 *   padding={0}
 *   paddingPerSide={true}
 *   paddingTop={0}
 *   paddingRight={0}
 *   paddingBottom={0}
 *   paddingLeft={0}
 *   momentum={false}
 *   dragEnabled={false}
 *   defaultEffect={PageEffect.Cube}>
 *   <Frame background="#19E" />
 *   <Frame background="#5CF" />
 *   <Frame background="#2CD" />
 * </Page>
 * ```
 * @public
 */
export interface PageProperties {
    /**
     * Current swipe direction. Either "horizontal" or "vertical". Set to `"horizontal"` by
     * default.
     *
     * @remarks
     * ```jsx
     * <Page direction="horizontal" />
     * ```
     */
    direction: PageDirection
    /**
     * Width of the pages within the component. Either "auto" or "stretch" or a numeric value. Set
     * to `"stretch"` by default.
     *
     * @remarks
     * ```jsx
     * <Page contentWidth="auto" />
     * ```
     */
    contentWidth: PageContentDimension | number
    /**
     * Height of the pages within the component. Either "auto" or "stretch" or a numeric value. Set
     * to `"stretch"` by default.
     *
     * @remarks
     * ```jsx
     * <Page contentHeight="auto" />
     * ```
     */
    contentHeight: PageContentDimension | number
    /**
     * Alignment of the pages within the component. Either "start", "center", or "end". Set to
     * `"start"` by default.
     *
     * @remarks
     * ```jsx
     * <Page alignment="center" />
     * ```
     */
    alignment: PageAlignment
    /**
     * Index of the current page. Set to `0` by default.
     *
     * @remarks
     * ```jsx
     * <Page currentPage={5} />
     * ```
     */
    currentPage: number
    /**
     * Determines whether the component should animate page changes. Set to `true` by default.
     *
     * @remarks
     * ```jsx
     * <Page animateCurrentPageUpdate={false} />
     * ```
     * @beta
     */
    animateCurrentPageUpdate: boolean

    /**
     * If `true`, this will lock dragging to the initial direction.
     *
     * @public
     *
     * ```jsx
     * <Page direction="both" directionLock={true} />
     * ```
     */
    directionLock?: boolean

    /**
     * Enable or disable dragging to scroll. Defaults to `true`.
     *
     * @public
     *
     * ```jsx
     * <Page dragEnabled={false} />
     * ```
     */
    dragEnabled?: boolean

    /**
     * Enable or disable wheel scroll. Defaults to `false`.
     *
     * @public
     *
     * ```jsx
     * <Page wheelEnabled={true} />
     * ```
     */
    wheelEnabled?: boolean

    /**
     * Horizontal offset of the scrollable content. Set to `0` by default
     *
     * @remarks
     * ```jsx
     * <Page contentOffsetX={20} />
     * ```
     */
    contentOffsetX?: MotionValue<number> | number

    /**
     * Vertical offset of the scrollable content. Set to `0` by default.
     *
     * @remarks
     * ```jsx
     * <Page contentOffsetY={20} />
     * ```
     */
    contentOffsetY?: MotionValue<number> | number

    /**
     * A number describing the gap between the page elements. Set to `10` by default. Can not be negative.
     *
     * @remarks
     * ```jsx
     * <Page gap={0} />
     * ```
     * */
    gap: number

    /**
     * Padding to be applied to all sides. Set to `0` by default.
     * To specify different padding for each side, provide
     * individual `paddingTop`, `paddingLeft`, `paddingRight` and `paddingBottom` values.
     *
     * ```jsx
     * <Page padding={20} />
     * ```
     */
    padding: number
    /**
     * Flag to tell the Page to ignore the `padding` prop and apply values per-side.
     *
     * @remarks
     *
     * ```jsx
     * <Page paddingLeft={20}  />
     * ```
     */
    paddingPerSide?: boolean
    /**
     * Value for the top padding of the container. Set to `0` by default.
     * ```jsx
     * <Page paddingTop={20}  />
     * ```
     */
    paddingTop?: number
    /**
     * ```jsx
     * <Page paddingRight={20}  />
     * ```
     * Value for the right padding of the container. Set to `0` by default.
     */
    paddingRight?: number
    /**
     * ```jsx
     * <Page paddingBottom={20}  />
     * ```
     * Value for the bottom padding of the container. Set to `0` by default.
     */
    paddingBottom?: number
    /**
     * ```jsx
     * <Page paddingLeft={20}  />
     * ```
     * Value for the left padding of the container. Set to `0` by default.
     */
    paddingLeft?: number
    /**
     * When enabled you can flick through multiple pages at once.
     * @remarks
     *
     * ```jsx
     * <Page momentum />
     * ```
     */
    momentum: boolean

    /**
     * Pick one of the predefined effects. Either "none", "cube", "coverflow", "wheel" or "pile". Set to `"none"` by default.
     * @remarks
     *
     * ```jsx
     * <Page defaultEffect={"coverflow"} />
     * ```
     */
    defaultEffect: PageEffect

    /**
     * Allows you to provide a custom transition effect for individual pages.
     *
     * This function is called once for every page, every time the scroll offset changes. It returns a new set of styles for this page.
     *
     * @param info - A {@link PageEffectInfo} object with information about the current effect.
     * @returns should return a new set of Frame properties.
     *
     * @remarks
     * ```jsx
     * function scaleEffect() {
     *     const { normalizedOffset } = info
     *     return {
     *         scale: Math.max(0, 1 + Math.min(0, normalizedOffset * -1))
     *     }
     * }
     *
     * return <Page effect={scaleEffect} />
     * ```
     * @public
     */
    effect?: (info: PageEffectInfo) => PageEffectValues

    /**
     * @internalremarks If `true`, specifies that the component is a direct child of a ComponentContainer, rendered by a CodeComponentNode that is placed on the canvas.
     * @internal
     * */
    __fromCodeComponentNode?: boolean
}

/**
 * The following are the props accepted by the Page component, these are all in
 * addition to the standard props accepted by all Frame components.
 * @public
 */
export interface PageProps
    extends PageProperties,
        Partial<Omit<FrameProps, "size" | "onScroll">>,
        LayerProps,
        Partial<PageEvents>,
        Partial<ScrollEvents> {}

/**
 * The Page component allows you to create horizontally or vertically swipeable areas. It can be
 * imported from the Framer Library and used in code components. Add children to create pages to
 * swipe between. These children will be stretched to the size of the page component by default,
 * but can also be set to auto to maintain their original size.
 *
 * @remarks
 * ```jsx
 * import * as React from "react"
 * import { Frame, Page } from "framer"
 * export class Page extends React.Component {
 *   render() {
 *     return (
 *       <Page>
 *         <Frame />
 *       </Page>
 *     )
 *   }
 * }
 * ```
 * @public
 */

export function Page(props: Partial<PageProps>) {
    const {
        direction = "horizontal",
        contentWidth = ContentDimension.Stretch,
        contentHeight = ContentDimension.Stretch,
        alignment = "start",
        currentPage = 0,
        animateCurrentPageUpdate = true,
        gap: gapValue = 10,
        padding = 0,
        momentum = false,
        dragEnabled = true,
        defaultEffect = "none",
        background = "transparent",
        overflow = "hidden",
        __fromCodeComponentNode,
        effect,
        children,
        contentOffsetX,
        contentOffsetY,
        onChangePage,
        onScrollStart,
        onScroll,
        onDragStart,
        onDrag,
        onDragEnd,
        directionLock,
        onScrollEnd,
        onDirectionLock,
        onUpdate,
        wheelEnabled = false,
        ...rest
    } = props

    const containerProps = { ...rest, background }
    if (props.__fromCodeComponentNode && !constraintsEnabled(unwrapFrameProps(props))) {
        containerProps.width = "100%"
        containerProps.height = "100%"
        containerProps._constraints = { enabled: true }
    }

    const { initial, prev } = React.useRef({
        initial: { x: 0, y: 0 },
        prev: { x: 0, y: 0 },
    }).current

    const isHorizontal = direction === "horizontal"
    let gap = gapValue
    if (gap < 0) {
        warnOnce(`The 'gap' property of Page component can not be negative, but is ${gapValue}.`)
        gap = 0
    }

    injectComponentCSSRules()

    const pageCount = React.Children.count(children)

    const [maxOffset, setMaxOffset] = React.useState<number>(0)
    const maxOffsetRef = React.useRef(maxOffset)
    maxOffsetRef.current = maxOffset

    const containerRef = React.useRef<HTMLDivElement>(null)
    const scrollableRef = React.useRef<HTMLDivElement>(null)

    const scrollControls = useAnimation()
    const pageEffectValuesRef = React.useRef<({ [key: string]: MotionValue } | undefined)[]>([])
    const pageRectsRef = React.useRef<Rect[]>([])
    const contentOffsetRef = React.useRef<{ x: MotionValue<number>; y: MotionValue<number> }>({
        x: isMotionValue(contentOffsetX) ? contentOffsetX : motionValue(contentOffsetX || 0),
        y: isMotionValue(contentOffsetY) ? contentOffsetY : motionValue(contentOffsetY || 0),
    })
    const currentContentPageRef = React.useRef<number>(0)
    const currentDirectionRef = React.useRef<PageDirection | null>(direction)
    const propsBoundedCurrentPageRef = React.useRef(0) // Bounded version of props.currentPage
    const latestPropsRef = React.useRef(props)
    latestPropsRef.current = props

    const offsetForPage = useOffsetForPage(pageCount, pageRectsRef, isHorizontal, maxOffsetRef)
    const snapToPage = useSnapToPage(
        offsetForPage,
        scrollControls,
        currentContentPageRef,
        contentOffsetRef,
        isHorizontal
    )

    let skipMeasureSize = false
    let initialSize = { width: 200, height: 200 }
    if (typeof containerProps.width === "number" && typeof containerProps.height === "number") {
        initialSize = { width: containerProps.width, height: containerProps.height }
        skipMeasureSize = true
    }

    const measuredContainerSize = useMeasureSize(containerRef, {
        observe: RenderTarget.current() === RenderTarget.preview,
        skipHook: skipMeasureSize,
        initial: initialSize,
    })

    const applyEffects = () => {
        pageEffectValuesRef.current.forEach((effectDictionary, index) => {
            const values = effectValues(index, latestPropsRef, pageRectsRef, contentOffsetRef, maxOffsetRef)
            if (!effectDictionary || !values) return
            for (const key in values) {
                if (isMotionValue(effectDictionary[key])) {
                    // Because these are the actual Animatable values passed to the Frame
                    // Updating their value will modify the Frame
                    effectDictionary[key].set(values[key])
                }
            }
        })
    }

    React.useLayoutEffect(() => {
        const contentOffset = contentOffsetRef.current
        contentOffset.x.onChange(applyEffects)
        contentOffset.y.onChange(applyEffects)
        const boundedCurrentPage = getBoundedCurrentPage(currentPage, pageCount)
        snapToPage(boundedCurrentPage)
    }, [])

    React.useLayoutEffect(() => {
        const newPageContentRects = getPageContentRects(containerRef, measuredContainerSize, direction, gap)
        if (newPageContentRects) pageRectsRef.current = newPageContentRects
        const newMaxOffset = getMaxOffset(measuredContainerSize, pageRectsRef.current, direction, props)
        if (newMaxOffset !== maxOffset) {
            setMaxOffset(newMaxOffset)
        }

        const newBoundedCurrentPage = getBoundedCurrentPage(currentPage, pageCount)
        const boundedCurrentPageDidChange = newBoundedCurrentPage !== propsBoundedCurrentPageRef.current

        if (currentDirectionRef.current !== direction) {
            currentDirectionRef.current = direction

            const contentOffset = contentOffsetRef.current
            contentOffset.x.set(0)
            contentOffset.y.set(0)
        } else if (boundedCurrentPageDidChange) {
            propsBoundedCurrentPageRef.current = newBoundedCurrentPage
            updateCurrentPage(newBoundedCurrentPage, currentContentPageRef, onChangePage)

            const animated = animateCurrentPageUpdate && RenderTarget.current() !== RenderTarget.canvas
            snapToPage(newBoundedCurrentPage, { animated })
        } else {
            snapToPage(newBoundedCurrentPage)
        }
    })

    const onDragStartHandler = (event: any, info: PanInfo) => {
        if (onScrollStart) onScrollStart(info)
        if (onDragStart) onDragStart(event, info)
        prev.x = initial.x = info.point.x
        prev.y = initial.y = info.point.y
    }

    const onDragHandler = (event: any, info: PanInfo) => {
        if (onScroll) onScroll(info)
        if (onDrag) onDrag(event, info)
        prev.x = info.point.x
        prev.y = info.point.y
    }
    const onDragTransitionEnd = () => {
        if (props.onDragTransitionEnd) props.onDragTransitionEnd()
        if (onScrollEnd) {
            const { x, y } = contentOffsetRef.current
            const point = { x: x.get(), y: y.get() }
            onScrollEnd({
                point,
                velocity: { x: x.getVelocity(), y: y.getVelocity() },
                offset: { x: point.x - initial.x, y: point.y - initial.y },
                delta: { x: point.x - prev.x, y: point.y - prev.y },
            })
        }
    }

    const onDragEndHandler = async (event: any, info: PanInfo) => {
        const contentOffset = isHorizontal ? contentOffsetRef.current.x : contentOffsetRef.current.y
        contentOffset.stop()

        const startPosition = contentOffset.get()
        const axis = isHorizontal ? "x" : "y"
        const velocity = info.velocity[axis]
        let index = nearestPageIndex(pageRectsRef.current, startPosition, startPosition, isHorizontal, momentum)

        if (velocity) {
            /**
             * TODO: This is a bit hacky. We're hijacking the inertia animation for the modifyTarget functionality. Maybe this is information we can
             * pass through the `onDragEnd` event handler if `dragMomentum` is `true`.
             */
            inertia({
                from: startPosition,
                velocity,
                modifyTarget: (endPosition: number) => {
                    index = nearestPageIndex(pageRectsRef.current, startPosition, endPosition, isHorizontal, momentum)
                    return endPosition
                },
            })
                .start()
                .stop()
        }

        updateCurrentPage(index, currentContentPageRef, onChangePage)

        const offset = offsetForPage(index)

        if (onDragEnd) onDragEnd(event, info)

        await scrollControls.start({
            [axis]: offset,
            transition: {
                type: "spring",
                from: startPosition,
                velocity: velocity,
                stiffness: 500,
                damping: 50,
            },
        })

        onDragTransitionEnd()
    }

    pageEffectValuesRef.current = []

    const childComponents = React.Children.map(children, (child: React.ReactElement<Partial<FrameProps>>, index) => {
        if (!isReactChild(child) || !isReactElement(child)) {
            return child
        }

        const update: { [key: string]: any } = {
            right: undefined,
            bottom: undefined,
            top: undefined,
            left: undefined,
            _constraints: {
                enabled: false,
            },
        }

        if (contentWidth === "stretch") {
            update.width = "100%"
        }
        if (contentHeight === "stretch") {
            update.height = "100%"
        }

        let effectDictionary: { [key: string]: MotionValue } | undefined

        const values = effectValues(index, latestPropsRef, pageRectsRef, contentOffsetRef, maxOffsetRef)

        if (values) {
            // We use motion values so we can set them in the onMove function
            effectDictionary = {}
            for (const key in values) {
                effectDictionary[key] = motionValue(values[key])
            }
        }

        pageEffectValuesRef.current.push(effectDictionary)

        return (
            <PageContainer
                key={index}
                effect={effectDictionary}
                dragEnabled={dragEnabled}
                direction={direction}
                contentHeight={contentHeight}
                contentWidth={contentWidth}
                alignment={alignment}
                gap={gap}
                isLastPage={index === pageCount - 1}
                contentOffset={contentOffsetRef.current}
                scrollControls={scrollControls}
                maxScrollOffset={maxOffset}
                directionLock={directionLock}
                onDragStart={onDragStartHandler}
                onDrag={onDragHandler}
                onDragEnd={onDragEndHandler}
            >
                {React.cloneElement(child, update)}
            </PageContainer>
        )
    })

    useWheelScroll(scrollableRef, {
        enabled: wheelEnabled,
        initial,
        prev,
        direction,
        dragConstraints: {
            top: -maxOffset,
            left: -maxOffset,
            right: 0,
            bottom: 0,
        },
        offsetX: contentOffsetRef.current.x,
        offsetY: contentOffsetRef.current.y,
        onScrollStart,
        onScroll,
        onScrollEnd,
    })

    return (
        <FrameWithMotion
            preserve3d={false}
            perspective={hasEffect(props) ? 1200 : undefined}
            overflow={overflow}
            {...containerProps}
            ref={containerRef}
        >
            <FrameWithMotion
                data-framer-component-type="Page"
                ref={scrollableRef}
                background={null}
                x={contentOffsetRef.current.x}
                y={contentOffsetRef.current.y}
                animate={scrollControls}
                width={"100%"}
                height={"100%"}
                preserve3d={true}
                style={{
                    padding: makePaddingString(paddingFromProps(props)),
                    display: "flex",
                    flexDirection: isHorizontal ? "row" : "column",
                }}
            >
                <EmptyState
                    children={children}
                    size={{
                        width: "100%",
                        height: "100%",
                    }}
                    insideUserCodeComponent={!__fromCodeComponentNode}
                />
                {childComponents}
            </FrameWithMotion>
        </FrameWithMotion>
    )
}

addPropertyControls(Page, {
    direction: {
        type: ControlType.SegmentedEnum,
        options: ["horizontal", "vertical"],
        title: "Direction",
        defaultValue: "horizontal",
    },
    directionLock: {
        type: ControlType.Boolean,
        title: "Lock",
        enabledTitle: "1 Axis",
        disabledTitle: "Off",
        defaultValue: true,
    },
    contentWidth: {
        type: ControlType.SegmentedEnum,
        options: pageContentDimensionOptions,
        optionTitles: pageContentDimensionTitles,
        title: "Width",
        defaultValue: ContentDimension.Stretch,
    },
    contentHeight: {
        type: ControlType.SegmentedEnum,
        options: pageContentDimensionOptions,
        optionTitles: pageContentDimensionTitles,
        title: "Height",
        defaultValue: ContentDimension.Stretch,
    },
    alignment: {
        type: ControlType.SegmentedEnum,
        options: pageAlignmentOptions,
        optionTitles(props) {
            if (!props) return genericAlignmentTitles
            const crossDirectionIsHorizontal = props.direction !== "horizontal"
            return crossDirectionIsHorizontal ? horizontalAlignmentTitles : verticalAlignmentTitles
        },
        title: "Align",
        hidden(props) {
            const { direction, contentWidth, contentHeight } = props
            const isHorizontalDirection = direction === "horizontal"
            const crossDimension = isHorizontalDirection ? contentHeight : contentWidth
            return crossDimension === ContentDimension.Stretch
        },
        defaultValue: "start",
    },
    gap: {
        type: ControlType.Number,
        min: 0,
        title: "Gap",
        defaultValue: 0,
    },
    padding: {
        type: ControlType.FusedNumber,
        toggleKey: "paddingPerSide",
        toggleTitles: ["Padding", "Padding per side"],
        valueKeys: ["paddingTop", "paddingRight", "paddingBottom", "paddingLeft"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
        title: "Padding",
        defaultValue: 0,
    },
    currentPage: {
        type: ControlType.Number,
        min: 0,
        title: "Current",
        displayStepper: true,
        defaultValue: 0,
    },
    momentum: {
        type: ControlType.Boolean,
        enabledTitle: "On",
        disabledTitle: "Off",
        title: "Momentum",
        defaultValue: false,
    },
    dragEnabled: {
        type: ControlType.Boolean,
        title: "Dragging",
        enabledTitle: "On",
        disabledTitle: "Off",
        defaultValue: true,
    },
    wheelEnabled: {
        type: ControlType.Boolean,
        title: "Wheel scroll",
        enabledTitle: "On",
        disabledTitle: "Off",
        defaultValue: false,
    },
    defaultEffect: {
        type: ControlType.Enum,
        options: pageEffectOptions,
        optionTitles: pageEffectTitles,
        title: "Effect",
        defaultValue: "none",
    },
    children: {
        type: ControlType.Array,
        title: "Content",
        propertyControl: { type: ControlType.ComponentInstance, title: "Page" },
    },
    onChangePage: {
        type: ControlType.EventHandler,
    },
    onScroll: {
        type: ControlType.EventHandler,
    },
    onScrollStart: {
        type: ControlType.EventHandler,
    },
    onScrollEnd: {
        type: ControlType.EventHandler,
    },
})
;(Page as any).supportsConstraints = true

// Effects

function cubeEffect(info: PageEffectInfo) {
    const { normalizedOffset, direction } = info
    const isHorizontal = direction === "horizontal"

    return {
        originX: normalizedOffset < 0 ? 1 : 0,
        originY: normalizedOffset < 0 ? 1 : 0,
        rotateY: isHorizontal ? Math.min(Math.max(-90, normalizedOffset * 90), 90) : 0,
        rotateX: isHorizontal ? 0 : Math.min(Math.max(-90, normalizedOffset * -90), 90),
        backfaceVisibility: "hidden",
        WebkitBackfaceVisibility: "hidden",
    }
}

function coverflowEffect(info: PageEffectInfo) {
    const { normalizedOffset, direction, size } = info
    const isHorizontal = direction === "horizontal"

    return {
        rotateY: isHorizontal ? Math.min(45, Math.max(-45, normalizedOffset * -45)) : 0,
        rotateX: isHorizontal ? 0 : Math.min(45, Math.max(-45, normalizedOffset * 45)),
        originX: isHorizontal ? (normalizedOffset < 0 ? 0 : 1) : 0.5,
        originY: isHorizontal ? 0.5 : normalizedOffset < 0 ? 0 : 1,
        x: isHorizontal ? `${normalizedOffset * -25}%` : 0,
        y: isHorizontal ? 0 : `${normalizedOffset * -25}%`,
        z: -Math.abs(normalizedOffset),
        scale: 1 - Math.abs(normalizedOffset / 10),
    }
}

function pileEffect(info: PageEffectInfo) {
    const { normalizedOffset, direction, size } = info
    const isHorizontal = direction === "horizontal"
    const offset = `calc(${Math.abs(normalizedOffset) * 100}% - ${Math.abs(normalizedOffset) * 8}px)`
    return {
        x: normalizedOffset < 0 && isHorizontal ? offset : 0,
        y: normalizedOffset < 0 && !isHorizontal ? offset : 0,
        scale: normalizedOffset < 0 ? 1 - Math.abs(normalizedOffset) / 50 : 1,
    }
}

function wheelEffect(info: PageEffectInfo) {
    const { normalizedOffset, direction, size } = info
    const isHorizontal = direction === "horizontal"

    const originZ = ((isHorizontal ? size.width : size.height) * 18) / (2 * Math.PI)
    const rotateX = isHorizontal ? 0 : normalizedOffset * -20
    const rotateY = isHorizontal ? normalizedOffset * 20 : 0
    const y = isHorizontal ? 0 : normalizedOffset * -size.height
    const x = isHorizontal ? normalizedOffset * -size.width : 0

    return {
        opacity: 1 - Math.abs(normalizedOffset) / 4,
        transform: `translate(${x}px, ${y}px) translateZ(-${originZ}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) translateZ(${originZ}px)`,
    }
}

function getDefaultEffect(type: PageEffect | undefined): CustomPageEffect | null {
    switch (type) {
        case "cube":
            return cubeEffect
        case "coverflow":
            return coverflowEffect
        case "pile":
            return pileEffect
        case "wheel":
            return wheelEffect
        default:
            return null
    }
}

function nearestPageIndex(
    pageRects: Rect[],
    startPosition: number,
    endPosition: number,
    isHorizontalDirection: boolean,
    allowSkippingPages: boolean
): number {
    const distanceToStart = function(rect: Rect): number {
        const rectPosition = isHorizontalDirection ? rect.x : rect.y
        return Math.abs(rectPosition + startPosition)
    }

    const distanceToEnd = function(rect: Rect): number {
        const rectPosition = isHorizontalDirection ? rect.x : rect.y
        return Math.abs(rectPosition + endPosition)
    }

    if (allowSkippingPages) {
        const closestPages = [...pageRects].sort((a, b) => distanceToEnd(a) - distanceToEnd(b))
        return pageRects.indexOf(closestPages[0])
    } else {
        const closestToStart = [...pageRects].sort((a, b) => distanceToStart(a) - distanceToStart(b))
        if (closestToStart.length === 1) return pageRects.indexOf(closestToStart[0])
        const pageA = closestToStart[0]
        const pageB = closestToStart[1]
        const closestPages = [pageA, pageB].sort((a, b) => distanceToEnd(a) - distanceToEnd(b))
        return pageRects.indexOf(closestPages[0])
    }
}

function getPageContentRects(
    containerRef: React.RefObject<HTMLDivElement>,
    containerSize: Size,
    direction: PageDirection,
    gap: number
): Rect[] | undefined {
    const containerElement = containerRef.current
    if (!containerElement) return

    // We need to keep strict selector here to have correct size if there is nested Page component
    const contentWrappers = containerElement.querySelectorAll(
        `:scope > [data-framer-component-type="Page"] > [data-framer-component-type="PageContainer"] > [data-framer-component-type="PageContentWrapper"]`
    )
    const sizes: (Size | null)[] = []
    contentWrappers.forEach(contentWrapper => {
        if (contentWrapper instanceof HTMLElement && contentWrapper.firstChild instanceof HTMLElement) {
            let width = contentWrapper.firstChild.offsetWidth
            let height = contentWrapper.firstChild.offsetHeight
            if (process.env.NODE_ENV === "test") {
                width = 100
                height = 100
            }
            sizes.push({ width, height })
        } else {
            sizes.push(null)
        }
    })

    let maxX = 0
    let maxY = 0

    const isHorizontal = direction === "horizontal"

    return sizes.map(queriedSize => {
        const size = queriedSize || containerSize
        const x = maxX
        const y = maxY
        if (isHorizontal) {
            maxX += size.width + gap
        } else {
            maxY += size.height + gap
        }
        return { ...size, x, y }
    })
}

function getMaxOffset(
    containerSize: Size,
    pageContentRects: Rect[],
    direction: PageDirection,
    paddingProps: PaddingProps
): number {
    const lastPageRect = pageContentRects[pageContentRects.length - 1]
    if (!lastPageRect) return 0
    const paddingSides = paddingFromProps(paddingProps)
    const isHorizontal = direction === "horizontal"
    const paddingStart = isHorizontal ? paddingSides.left : paddingSides.top
    const paddingEnd = isHorizontal ? paddingSides.right : paddingSides.bottom
    const pageWidth = isHorizontal ? lastPageRect.width : lastPageRect.height
    const containerWidth = isHorizontal ? containerSize.width : containerSize.height
    const freeSpace = containerWidth - paddingStart - paddingEnd - pageWidth
    const target = isHorizontal ? lastPageRect.x : lastPageRect.y
    if (freeSpace <= 0) return target
    return target - freeSpace
}

function useOffsetForPage(
    pageCount: number,
    pageRectsRef: React.MutableRefObject<Rect[]>,
    isHorizontal: boolean,
    maxOffsetRef: React.MutableRefObject<number>
) {
    return (index: number) => {
        const pageIndex = Math.max(0, Math.min(pageCount - 1, index))
        const currentPageRect = pageRectsRef.current[pageIndex]
        if (!currentPageRect) {
            return 0
        }
        if (isHorizontal) {
            return -Math.min(currentPageRect.x, maxOffsetRef.current)
        } else {
            return -Math.min(currentPageRect.y, maxOffsetRef.current)
        }
    }
}

function useSnapToPage(
    offsetForPage: (index: number) => number,
    scrollControls: AnimationControls,
    currentContentPageRef: React.MutableRefObject<number>,
    contentOffsetRef: React.MutableRefObject<{ x: MotionValue<number>; y: MotionValue<number> }>,
    isHorizontal: boolean
) {
    return (pageIndex: number, options?: { animated: boolean }) => {
        const offset = offsetForPage(pageIndex)
        currentContentPageRef.current = pageIndex
        const contentOffset = isHorizontal ? contentOffsetRef.current.x : contentOffsetRef.current.y

        if (!options || !options.animated) {
            contentOffset.set(offset)
            return
        } // else

        const axis = isHorizontal ? "x" : "y"
        scrollControls.start({
            [axis]: offset,
            transition: {
                type: "spring",
                from: contentOffset.get(),
                velocity: contentOffset.getVelocity(),
                stiffness: 500,
                damping: 50,
            },
        })
    }
}

// The current page property is capped to the number of children when positive, and cycles from last when negative
function getBoundedCurrentPage(pageIndex: number, pageCount: number) {
    return pageIndex >= 0 ? Math.min(pageIndex, pageCount - 1) : ((pageIndex % pageCount) + pageCount) % pageCount
}

function effectValues(
    index: number,
    latestPropsRef: React.MutableRefObject<Partial<PageProps>>,
    pageRectsRef: React.MutableRefObject<Rect[]>,
    contentOffsetRef: React.MutableRefObject<{ x: MotionValue<number>; y: MotionValue<number> }>,
    maxOffsetRef: React.MutableRefObject<number>
): PageEffectValues | null {
    const {
        direction: latestDirection = "horizontal",
        defaultEffect: latestDefaultEffect,
        effect: latestEffect,
        gap: latestGap = 0,
    } = latestPropsRef.current
    const latestIsHorizontal = latestDirection === "horizontal"

    const pageRect: {
        x: number
        y: number
        width: number
        height: number
    } = pageRectsRef.current[index] || {
        x: latestIsHorizontal ? index * 200 + latestGap : 0,
        y: latestIsHorizontal ? 0 : index * 200 + latestGap,
        width: 200,
        height: 200,
    }

    const effectFunction = latestEffect || getDefaultEffect(latestDefaultEffect)
    if (!effectFunction) return null

    let offset: number
    let normalizedOffset: number
    const contentOffset = contentOffsetRef.current
    const maxScrollOffset = maxOffsetRef.current
    if (latestIsHorizontal) {
        offset = Math.min(pageRect.x, maxScrollOffset) + (contentOffset ? contentOffset.x.get() : 0)
        normalizedOffset = offset / (pageRect.width + latestGap)
    } else {
        offset = Math.min(pageRect.y, maxScrollOffset) + (contentOffset ? contentOffset.y.get() : 0)
        normalizedOffset = offset / (pageRect.height + latestGap)
    }

    const size = { width: pageRect.width, height: pageRect.height }

    return effectFunction({
        offset,
        normalizedOffset,
        size,
        index,
        direction: latestDirection,
        gap: latestGap,
        pageCount: pageRectsRef.current.length,
    })
}

function hasEffect(props: Partial<PageProperties>) {
    return !!props.effect || !!getDefaultEffect(props.defaultEffect)
}

function updateCurrentPage(
    newPageIndex: number,
    currentContentPageRef: React.MutableRefObject<number>,
    onChangePage: ((currentIndex: number, previousIndex: number) => void) | undefined
) {
    if (currentContentPageRef.current === newPageIndex) return
    if (onChangePage) onChangePage(newPageIndex, currentContentPageRef.current)
    currentContentPageRef.current = newPageIndex
}
