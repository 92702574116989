import * as React from "react"
import { ComponentPlaceholder, PlaceholderType } from "./ComponentPlaceholder"
import { safeWindow } from "../../utils/safeWindow"

export const ComponentContainerLoader: React.SFC<any> = props => {
    if (process.env.NODE_ENV !== "production" && safeWindow["perf"]) safeWindow["perf"].nodeRender()
    let type: PlaceholderType = PlaceholderType.Loading
    let title: string | undefined
    let message: string | undefined

    if (props.error) {
        type = PlaceholderType.Error
        const { error, file } = props.error
        title = file
        message = error instanceof Error ? error.message : error
    }

    return <ComponentPlaceholder type={type} title={title} message={message} />
}
