import { safeWindow } from "../../utils/safeWindow"

/**
 * The `RenderTarget` represents the current environment in which a component
 * is running. This is most commonly either the editor canvas in the Framer X
 * application or in the generated preview window.
 *
 * @remarks
 * Code components can use the `RenderTarget.current()` method to check for
 * the environment within their components and vary rendering accordingly. The
 * most common case would be to improve performance while rendering in the
 * Framer X canvas where components that take too long to render will be
 * replaced with a placeholder. The
 * `RenderTarget.hasRestrictions()` method can be used to check explicitly
 * for this case.
 *
 * @public
 */
export enum RenderTarget {
    /**
     * The component is to be rendered for the Framer X canvas.
     *
     * @remarks
     * ```jsx
     * function App() {
     *   if (RenderTarget.current() === RenderTarget.canvas) {
     *     return <CanvasComponent />
     *   }
     *   return <DefaultComponent />
     * }
     * ```
     */
    canvas = "CANVAS",
    /**
     * The component is to be rendered for export.
     *
     * @remarks
     * ```jsx
     * function App() {
     *   if (RenderTarget.current() === RenderTarget.export) {
     *     return <ExportComponent />
     *   }
     *   return <DefaultComponent />
     * }
     * ```
     */
    export = "EXPORT",
    /**
     * The component is to be rendered as a preview thumbnail, for example in the
     * component panel.
     *
     * @remarks
     * ```jsx
     * function App() {
     *   if (RenderTarget.current() === RenderTarget.thumbnail) {
     *     return <Thumbnail />
     *   }
     *   return <DefaultComponent />
     * }
     * ```
     */
    thumbnail = "THUMBNAIL",
    /**
     * The component is being rendered in the preview window.
     *
     * @remarks
     * ```jsx
     * function App() {
     *   React.useEffect(() => {
     *     if (RenderTarget.current() === RenderTarget.preview) {
     *       // Do something in preview.
     *     }
     *   })
     *   return <DefaultComponent />
     * }
     * ```
     */
    preview = "PREVIEW",
}

/**
 * @internal
 */
export interface RenderEnvironment {
    imageBaseURL: string
    target: RenderTarget
    zoom: number
}

const windowKey = "FramerRenderEnvironment"

/**
 * @internal
 */
export const RenderEnvironment: RenderEnvironment = {
    imageBaseURL: "",
    target: RenderTarget.preview,
    zoom: 1,
    // Allow the environment to be pre-defined.
    ...safeWindow[windowKey],
}

/**
 * This is used to temporarily execute a task in a different render environment (for example during export)
 * @internal
 */
export function executeInRenderEnvironment<T>(customEnvironment: Partial<RenderEnvironment>, task: () => T): T {
    // Copy currentEnvironment
    const previousEnvironment = Object.assign({}, RenderEnvironment)
    // Set the customEnvironment to the current environment
    Object.assign(RenderEnvironment, customEnvironment)
    const result = task()
    // Reset the previous environment back on the currentEnvironment
    Object.assign(RenderEnvironment, previousEnvironment)
    return result
}

/**
 * This function sets the global render environment Framer Core uses to render.
 * Because it sets global state, there should be only one thing responsable for calling it in every react app (e.g. Vekter and Preview)
 * @internal
 */
export function setGlobalRenderEnvironment(environment: Partial<RenderEnvironment>) {
    Object.assign(RenderEnvironment, environment)
}

/**
 * @internalRemarks
 * This is a read-only equivalent of RenderEnvironment.target that is exposed
 * to components for context-dependent rendering
 * @public
 */
export namespace RenderTarget {
    /**
     * Returns the current `RenderTarget` allowing components to apply
     * different behaviors depending on the environment.
     *
     * @remarks
     * ```jsx
     * function App() {
     *   if (RenderTarget.current() === RenderTarget.thumbnail) {
     *     return <PreviewIcon />
     *   }
     *   return <Frame>...</Frame>
     * }
     * ```
     */
    export function current(): RenderTarget {
        return RenderEnvironment.target
    }

    /**
     * Returns true if the current `RenderTarget` has performance restrictions.
     * Use this to avoid doing heavy work in these contexts because they may
     * bail on the rendering if the component takes too long.
     *
     * @remarks
     * ```jsx
     * function App() {
     *   if (RenderTarget.hasRestrictions()) {
     *     return <SomePlaceholder />
     *   }
     *   return <RichPreviewContent />
     * }
     * ```
     */
    export function hasRestrictions(): boolean {
        const target = RenderEnvironment.target
        if (target === RenderTarget.canvas) return true
        if (target === RenderTarget.export) return true
        return false
    }
}
