import * as React from "react"
import { RefObject, useState, useLayoutEffect } from "react"
import { Size } from "../../render/types/Size"
import { useResizeObserver } from "./useResizeObserver"

function reportSize(element: HTMLElement | null, size: Size, setSize: (size: Size) => void) {
    if (!element) {
        return
    }
    const height = element.offsetHeight
    const width = element.offsetWidth
    if (size.width !== width || size.height !== height) {
        setSize({
            width,
            height,
        })
    }
}

export function useMeasureSize(
    ref: RefObject<HTMLElement>,
    options: Partial<{ observe: boolean; skipHook: boolean; initial: Size }> = {}
): Size {
    const { observe = false, skipHook = false, initial = { width: 0, height: 0 } } = options
    const [size, setSize] = useState<Size>(initial)

    const onResize = () => {
        reportSize(ref.current, size, setSize)
    }

    useResizeObserver(ref, onResize, { skipHook: skipHook || !observe })
    useLayoutEffect(() => {
        if (skipHook || observe) {
            return
        }
        reportSize(ref.current, size, setSize)
    })
    return skipHook ? initial : size
}
