export type TypefaceSourceName = "local" | "google"

export enum TypefaceSourceNames {
    Google = "google",
    Local = "local",
}

export interface FontSource {
    name: TypefaceSourceName
    typefaces: Typeface[]
    byFamily: Map<string, Typeface>
}
export interface Typeface {
    source: TypefaceSourceName
    family: string
    fonts: Font[]
}
export interface Font {
    typeface: Typeface
    variant: string
    /**
     * normal / italic / oblique
     * The font-style of the font
     * Can be parsed from the variant when using google gonts
     */
    style?: string
    /**
     * 100-800
     * The font-weight of the font
     * Can be parsed from the variant when using google gonts
     */
    weight?: number
    selector: string
    status?: "loaded"
}
/** The data required to locate a typeface */
export interface TypefaceLocator {
    source: TypefaceSourceName
    family: string
}
/** The data required to locate a font */
export interface WebFontLocator extends TypefaceLocator {
    variant: string
}
/**
 * Information about a font variant
 * For example: `parseVariant("600italic")` will return `{style: 'italic', weight: 600}`
 **/
export interface FontVariant {
    /** "normal" | "italic" | "oblique" */
    style: string
    /** 100 / 200 / 300 / 400 / 500 / 600 / 700 / 800 */
    weight: number
}

/**
 * A mapped type that deeply changes all properties into readonly
 * all arrays into ReadonlyArray<T>
 * all maps into ReadonlyMap<T>
 */
type DeepReadonly<T> = {
    readonly [P in keyof T]: T[P] extends Map<infer K, infer V>
        ? ReadonlyMap<K, V>
        : T[P] extends Array<infer X>
        ? ReadonlyArray<X>
        : DeepReadonly<T[P]>
}

export type ReadonlyFont = DeepReadonly<Font>
export type ReadonlyTypeface = DeepReadonly<Typeface>
