import { RefObject, useState, useEffect } from "react"
import { Size } from "../../render/types/Size"
import { useResizeObserver } from "./useResizeObserver"

function reportSize(element: HTMLElement | null, size: Size, setSize: (size: Size) => void) {
    if (!element) {
        return
    }
    const children = [...element.children]
    let maxHeight = 0
    let maxWidth = 0

    children.forEach((child: HTMLElement) => {
        maxHeight = Math.max(maxHeight, child.scrollHeight + child.offsetTop)
        maxWidth = Math.max(maxWidth, child.scrollWidth + child.offsetLeft)
    })

    if (size.width !== maxWidth || size.height !== maxHeight) {
        setSize({
            width: maxWidth,
            height: maxHeight,
        })
    }
}

export function useChildrenSize(
    ref: RefObject<HTMLElement>,
    options: Partial<{ observe: boolean; skipHook: boolean; initial: Size }> = {}
): Size {
    const { observe = false, skipHook = false, initial = { width: 0, height: 0 } } = options
    const [size, setSize] = useState<Size>(initial)

    const onResize = () => {
        reportSize(ref.current, size, setSize)
    }

    useResizeObserver(ref, onResize, { skipHook: skipHook || !observe, observeChildren: true })

    useEffect(() => {
        if (skipHook || observe) {
            return
        }
        reportSize(ref.current, size, setSize)
    })

    return skipHook ? initial : size
}
