import {
    ComponentLoader,
    ComponentDefinition,
    ComponentIdentifier,
    DesignComponentDefinition,
    PackageIdentifier,
} from "./definition"
import { localPackageFallbackIdentifier } from "./package"
export * from "./definition"

// TODO: Don't store state globally.
/**
 * @internal
 */
export const componentLoader: ComponentLoader = {
    componentForIdentifier: (identifier: ComponentIdentifier) => {
        return null
    },
    componentsForPackage: (packageIdentifier: PackageIdentifier) => {
        return []
    },
    componentIdentifiers: () => {
        return []
    },
    errorForIdentifier: (identifier: ComponentIdentifier) => {
        return null
    },
    forEachComponent: (cb: (component: ComponentDefinition) => boolean): void => {},
    forEachDesignComponents: (cb: (component: DesignComponentDefinition) => void): void => {},
    localPackageIdentifier: () => {
        return localPackageFallbackIdentifier
    },
    packageDisplayName: (packageIdentifier: PackageIdentifier): string | undefined => {
        return undefined
    },
    packageIdentifiers: (): PackageIdentifier[] => {
        return []
    },
    tokensForPackage: (packageIdentifier: PackageIdentifier) => {
        return {}
    },
    packageFileNames: (packageIdentifier: PackageIdentifier): string[] => {
        return []
    },
}
