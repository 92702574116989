import { GradientColorStop } from "./GradientColorStop"

/**
 * @public
 */
export interface MultiStopGradient {
    stops: GradientColorStop[]
}

const multiStopGradientKeys: (keyof MultiStopGradient)[] = ["stops"]

export function isMultiStopGradient(value: any): value is MultiStopGradient {
    return value && multiStopGradientKeys.every(key => key in value)
}
