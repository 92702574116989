import * as React from "react"

/**
 * Modifies children to forward overrides of design components to their childern.
 * _overrideForwardingDescription is created by FrameNode and contains all the values of forwarded overrides
 * They are extracted here and passed into _forwardedOverrides of the children, or added to an already existing _forwardedOverrides prop
 */
interface ProcessOverrideForwardingReturnType {
    props: any
    children: React.ReactNode | undefined
}

export function processOverrideForwarding(props: any, children?: React.ReactNode): ProcessOverrideForwardingReturnType {
    if (!children) {
        children = props.children
        if (!children) return { props, children }
    }

    let _forwardedOverrides: { [key: string]: any } | undefined = props._forwardedOverrides
    const extractions = props._overrideForwardingDescription
    if (extractions) {
        _forwardedOverrides = undefined
        for (const key in extractions) {
            const propName = extractions[key]
            const value = props[propName]
            if (value !== undefined) {
                if (!_forwardedOverrides) {
                    _forwardedOverrides = {}
                    props = { ...props }
                }
                _forwardedOverrides[key] = props[propName]
                delete props[propName]
            }
        }
    }

    if (!_forwardedOverrides) return { props, children }

    children = React.Children.map(children, (child: React.ReactNode) => {
        return React.cloneElement(child as any, { _forwardedOverrides })
    })

    return { props, children }
}
