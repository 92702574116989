import * as React from "react"
import { DeprecatedFrameWithEventsProps, FrameWithMotion, FrameProps } from "../render/presentation/Frame"
import { isEqual, isFiniteNumber } from "../render"
import { Transition, MotionValue } from "framer-motion"
import { NavigationTransitionPosition } from "./NavigationTransitions"

export interface Props {
    position: NavigationTransitionPosition | undefined
    initialProps: Partial<FrameProps> | undefined
    transitionProps: Partial<FrameProps> | undefined

    instant: boolean
    animation: Transition

    visible: boolean
    backfaceVisible?: boolean
    hideAfterTransition: boolean

    onTapBackdrop: (() => void) | undefined
    backdropColor: string | undefined
}

export type AnimatingProperties = Partial<DeprecatedFrameWithEventsProps> & { dimOpacity: number }
export interface State {
    visible: boolean
    containerPerspective: 1200 | 0
    previousProps: Props | null
    origins: OriginProps
}

export class NavigationContainer extends React.Component<Props, State> {
    private unmounted = false

    componentWillUnmount() {
        this.unmounted = true
    }

    state: State = {
        visible: true,
        containerPerspective: 0,
        previousProps: null,
        origins: {},
    }

    onTransitionEnd = () => {
        if (this.unmounted) return

        const visible = this.props.hideAfterTransition ? false : this.state.visible
        const containerPerspective = needsPerspective(this.props.transitionProps) ? 1200 : 0

        if (visible !== this.state.visible || containerPerspective !== this.state.containerPerspective) {
            this.setState({
                visible,
                containerPerspective,
            })
        }
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (isEqual(props, state.previousProps)) return null

        const newState: State = { ...state, previousProps: props }

        const shouldBeVisible = props.visible && !props.hideAfterTransition
        if (shouldBeVisible) {
            newState.visible = true
        }
        if (!props.visible) {
            newState.visible = false
        }

        const wantsPerspective = contains3Dprops(props.transitionProps) || contains3Dprops(props.initialProps)
        newState.containerPerspective = wantsPerspective ? 1200 : 0

        newState.origins = getOriginProps(state.origins, props.initialProps, props.transitionProps)

        return newState
    }

    componentDidUpdate() {
        const instant = this.props.instant || this.props.animation.type === false
        if (instant) this.onTransitionEnd()
    }

    render() {
        const {
            backdropColor,
            onTapBackdrop,
            backfaceVisible,
            hideAfterTransition,
            animation,
            instant,
            initialProps,
            transitionProps,
            position = { top: 0, right: 0, bottom: 0, left: 0 },
        } = this.props

        const { visible, containerPerspective, origins } = this.state

        const transition: Transition = instant ? { type: false } : animation

        const layout: Partial<FrameProps> = { ...position }
        if (layout.left === undefined || layout.right === undefined) layout.width = "auto"
        if (layout.top === undefined || layout.bottom === undefined) layout.height = "auto"

        return (
            <FrameWithMotion
                width={"100%"}
                height={"100%"}
                style={{
                    position: "absolute",
                    transformStyle: "flat",
                    background: "transparent",
                    overflow: "hidden",
                    perspective: containerPerspective,
                    visibility: visible ? "visible" : "hidden",
                }}
            >
                <FrameWithMotion
                    width={"100%"}
                    height={"100%"}
                    transition={transition}
                    initial={{ opacity: instant && visible ? 1 : 0 }} // To prevent flashing
                    animate={{ opacity: hideAfterTransition ? 0 : 1 }}
                    background={backdropColor ? backdropColor : "transparent"}
                    onTap={onTapBackdrop}
                />
                <FrameWithMotion
                    {...layout}
                    initial={{ ...allAnimatableProperties, ...origins, ...initialProps } as any}
                    animate={{ ...allAnimatableProperties, ...origins, ...transitionProps } as any}
                    transition={{
                        default: transition,
                        originX: { type: false },
                        originY: { type: false },
                        originZ: { type: false },
                    }}
                    background={"transparent"}
                    backfaceVisible={backfaceVisible}
                    data-framer-component-type={"NavigationContainer"}
                    onAnimationComplete={this.onTransitionEnd}
                >
                    {this.props.children}
                    {hideAfterTransition && (
                        <FrameWithMotion // Block pointer events from starting a new transition
                            position={"absolute"}
                            top={0}
                            left={0}
                            width={"100%"}
                            height={"100%"}
                            backgroundColor={"transparent"}
                            style={{ pointerEvents: "auto" }}
                        />
                    )}
                </FrameWithMotion>
            </FrameWithMotion>
        )
    }
}

type OriginProps = Partial<{
    originX: number | string | MotionValue
    originY: number | string | MotionValue
    originZ: number | string | MotionValue
}>

function getOriginProps(
    currentOriginProps: OriginProps,
    initialProps: OriginProps | undefined,
    transitionProps: OriginProps | undefined
): OriginProps {
    const result: OriginProps = { ...currentOriginProps }

    if (initialProps) {
        if (isFiniteNumber(initialProps.originX)) result.originX = initialProps.originX
        if (isFiniteNumber(initialProps.originY)) result.originY = initialProps.originY
        if (isFiniteNumber(initialProps.originZ)) result.originZ = initialProps.originZ
    }

    if (transitionProps) {
        if (isFiniteNumber(transitionProps.originX)) result.originX = transitionProps.originX
        if (isFiniteNumber(transitionProps.originY)) result.originY = transitionProps.originY
        if (isFiniteNumber(transitionProps.originZ)) result.originZ = transitionProps.originZ
    }

    return result
}

function needsPerspective(containerProps: Partial<FrameProps> | undefined) {
    if (!containerProps) return false
    const { rotateX, rotateY, z } = containerProps
    if (isFiniteNumber(rotateX) && rotateX % 180 !== 0) return true
    if (isFiniteNumber(rotateY) && rotateY % 180 !== 0) return true
    if (isFiniteNumber(z) && z !== 0) return true
    return false
}

function contains3Dprops(containerProps: Partial<FrameProps> | undefined) {
    if (!containerProps) return false
    return "rotateX" in containerProps || "rotateY" in containerProps || "z" in containerProps
}

const allAnimatableProperties: Partial<FrameProps> = {
    x: 0,
    y: 0,
    z: 0,
    rotate: 0,
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    scale: 1,
    scaleX: 1,
    scaleY: 1,
    scaleZ: 1,
    skew: 0,
    skewX: 0,
    skewY: 0,
    originX: 0.5,
    originY: 0.5,
    originZ: 0,
    opacity: 1,
}
