import { NavigationTransitionDirection, NavigationTransitionBackdropColor } from "../../render"

/**
 * @internal
 * @deprecated
 */
export enum NavigateTo {
    Previous = "@Previous",
}

/**
 * @internal
 * @deprecated
 */
export type NavigationTarget = string | NavigateTo.Previous

/**
 * @internal
 * @deprecated
 */
export enum NavigationTransitionType {
    push = "push",
    instant = "instant",
    fade = "fade",
    modal = "modal",
    overlay = "overlay",
    flip = "flip",
}

/**
 * @internal
 * @deprecated
 */
export type NavigationTransitionDirection = "left" | "right" | "up" | "down"

/**
 * @internal
 * @deprecated
 */
export interface NavigationLink {
    navigationTarget: NavigationTarget
    navigationTransition: NavigationTransitionType
    navigationTransitionDirection: NavigationTransitionDirection
    navigationTransitionOverrides?: NavigationTransitionBackdropColor
}
