import { hash as stringHash } from "../../utils/string"
import { ConvertColor } from "../types/Color"
import { GradientColorStop } from "../types/GradientColorStop"
import { SimpleGradient } from "../types/SimpleGradient"
import { MultiStopGradient } from "../types/MultiStopGradient"
import { memoize } from "../../utils/memoize"

const CSSVariableRegExp = /var\(.+\)/

const colorCache = new Map<[string, number], string>()
function cachedMultiplyAlpha(s: string, a: number): string {
    const key: [string, number] = [s, a]
    if (CSSVariableRegExp.test(s)) {
        return s
    }
    return memoize(1000, colorCache, key, () => ConvertColor.multiplyAlpha(s, a))
}

/**
 * @param alpha - is optional because SVGs define the alpha as a seperate property
 * @internal
 */
export function gradientColorStops(gradient: SimpleGradient | MultiStopGradient, alpha = 1): GradientColorStop[] {
    let stops: GradientColorStop[]
    if ("stops" in gradient) {
        stops = gradient.stops
    } else {
        stops = [{ value: gradient.start, position: 0 }, { value: gradient.end, position: 1 }]
    }

    if (alpha === 1) {
        return stops
    } else {
        return stops.map(stop => ({ ...stop, value: cachedMultiplyAlpha(stop.value, alpha) }))
    }
}

export function gradientColorStopsHash(gradient: SimpleGradient | MultiStopGradient, alpha: number) {
    let result = 0
    gradientColorStops(gradient, alpha).forEach((stop: GradientColorStop) => {
        result ^= stringHash(stop.value) ^ stop.position
    })
    return result
}
